<template>
  <div class="dashboard-content">
    <div class="row">
       <div class="col-lg-12 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="dashboard-list-box-static">
           <div class="row">
                <div class="col-md-6">
                    <h6 class="mb-2" style="text-transform:uppercase">
                    Select The Ad You Want To Purchase
                    </h6>
                </div>
                <div class="col-md-6 text-right">
                    <button @click="deleteBusiness" class="btn btn-danger btn-sm"> <i class="fa fa-trash" aria-hidden="true"></i> Delete Business </button>
                </div>
           </div>
            <hr>
            <div class="row">
                <div class="col-md-8 table-responsive">
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <td scope="col" class="uppercase" > 
                                    <router-link to="/purchase-realtor-ad">Realtor Ad - Purchase/Edit Your Zip Code</router-link>
                                </td>
                                <td scope="col" class="uppercase" > 
                                    <router-link to="#">
                                        <button v-if="self_realtor"  @click="deleteRealtorAd" class="btn btn-danger btn-sm">
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                Delete Ad
                                        </button>
                                         <button v-else  :disabled="child_realtor_ads <= 0" @click="deleteRealtorAd" class="btn btn-danger btn-sm">
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                Delete Ad
                                        </button>
                                    </router-link>
                                </td>
                            </tr>
                            <tr>
                                <td scope="col" class="uppercase" > 
                                    <router-link to="/purchase-sponsor-ad">Deals-Events-Sponsored Ad - Purchase/Edit Any Zip Code</router-link>
                                </td>
                                <td scope="col" class="uppercase" > 
                                    <router-link to="#">
                                        <button class="btn btn-danger btn-sm">
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                Delete Ad
                                        </button>
                                    </router-link>
                                </td>
                            </tr>
                            <tr>
                                <td scope="col" class="uppercase" > 
                                    <router-link to="/purchase-child-ad">Realtor Ad - Purchase/Edit Zip Code of All Added Locations</router-link>
                                </td>
                                <td scope="col" class="uppercase" > 
                                    <router-link to="#">
                                        <button @click="deleteAddedRealtorAd" :disabled="!child_realtor_ads" class="btn btn-danger btn-sm">
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                Delete Ad
                                        </button>
                                    </router-link>
                                </td>
                            </tr>
                            
                        </thead>
                    </table>
                </div>
            </div>
            <hr>
            <div class="row container">
                <h5>Added Locations ({{total_locations}})</h5><br>
            </div>
            <div class="row container">
                <p class="font-weight-bold">Select Location For Control Panel Access To Place Ad for That Location.</p>
            </div>
            <div class="row ">
                <div class="col-md-4">
                    <div class="form-group">
                      <v-select
                       
                        :options="zipcodes"
                        placeholder="Location Address"
                        class="zipcode"
                      
                      />
                    </div>
                  </div>
                    <div class="col-md-5">
                        <router-link to="/add-location"  class="btn btn-success" style="font-size:16px;padding:5px 12px 5px 12px;"> + </router-link >
                    </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="copyrights">
          © 2021 Thriggle. All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {axios} from '../../services/http-common';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
export default {
    data(){
        return {
            zipcodes: [],
            self_realtor:'' ,
            other_realtors :'',
            sponsor_ads:'',
            child_realtor_ads:'',
            total_locations:0,
        }
    },
    components: {
      vSelect:vSelect,
	},
    methods:{
       deleteRealtorAd(){

           this.$swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
            return axios
            .post(`${process.env.VUE_APP_API_URL}delete-realtor`)
            .then(response => {
            if (!response.error) {
                if (result.isConfirmed) {
                this.$toast.success('Ad Has Been Deleted', {
                    position: "bottom-right",
                    timeout: 2508,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                    top:'300px',
                    });
            }
                
            }
         }).catch(error  => {
             this.$toast.error('Ad Not Found', {
                    position: "bottom-right",
                    timeout: 2508,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                    top:'300px',
                    });
         });
                
            }
        })
           
       },
        deleteBusiness(){
            this.$swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                this.$toast.success('Your Account Has Been Deleted', {
                    position: "bottom-right",
                    timeout: 2508,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                    top:'300px',
                    });
            }
        }).catch( error => {
            this.$toast.error('Something Went Wrong! ', {
                    position: "bottom-right",
                    timeout: 2508,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                    top:'300px',
                    });
        })
        },
        adDetails(){
             return axios
            .get(`${process.env.VUE_APP_API_URL}ad-details`)
            .then(response => {
               this.self_realtor = response.data.data.self_realtor;
               this.other_realtors = response.data.data.other_realtors;
               this.sponsor_ads = response.data.data.sponsor_ads;
               this.child_realtor_ads = response.data.data.child_realtor_ads;
               localStorage.setItem('other_realtor_buy_ad', response.data.data.other_realtor_buy_ad);
               localStorage.setItem('self_realtor', response.data.data.self_realtor);
        
         });
        },
        totalLocations(){
            return axios
                .get(`${process.env.VUE_APP_API_URL}total_locations`)
                .then(response => {
                this.total_locations = response.data;
            }).catch(error => {
                this.$toast.error('Something Went Wrong!', {
                    position: "bottom-right",
                    timeout: 2508,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                    top:'300px',
                    });
            });
        },
        deleteAddedRealtorAd(){


            
           this.$swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
             return axios
            .post(`${process.env.VUE_APP_API_URL}delete-added-realtor-ad`)
            .then(response => {
               
            if (!response.error) {
                if (result.isConfirmed) {
                this.$toast.success('All Ads Has Been Deleted', {
                    position: "bottom-right",
                    timeout: 2508,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                    top:'300px',
                    });
            }
                
            }
         }).catch(error  => {
             this.$toast.error('Ad Not Found', {
                    position: "bottom-right",
                    timeout: 2508,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                    top:'300px',
                    });
         });
                
            }
        })



            
        }
    },
    mounted() {
    this.adDetails();
    this.totalLocations();
       
    }
}

</script>
<style scoped>
.dashboard-list-box ul li{
    padding: 13px 30px !important;
}
.uppercase{
    text-transform: uppercase !important;
}
</style>
